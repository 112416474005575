<template>
	<div class="list">
		<!-- <img src="../../assets/img/xintp/sybj.png" alt=""> -->
         <img src="../../assets/img/xintp/banner.jpg" alt="" style="width:100%;height:auto">
		<router-view @getLoad="showMsg" />
	</div>
</template>

<script>
	export default {

		data() {
			return {
				active:this.$route.params.newsid
			}
		},
		methods:{
			showMsg(){
				var that = this
				that.$emit('getLoad', true);
			},
			
			nato(e){
				var that=this
				that.active=e
				that.$router.push('/daxt/newsd/'+e)
			}
		}
	}
</script>

<style>

</style>
